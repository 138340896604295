<template>
  <div>
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        width="60%"
    >
      <v-card flat min-height="80vh" style="position: relative">
        <div class="close">
          <v-btn fab depressed small @click.stop="navigation=false" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-toolbar color="grey darken-3" dark elevation="1">
          <v-btn dark color="secondary" depressed :disabled="!selectedItem" @click.stop="chooseByRow">Обрати</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="overflow: hidden" class="px-0">
          <UnitTypeView @selectedItemRow="selectedRow" :selectable="true" :model="navigation"/>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <MainModal
        :main="{ component: 'UnitType', title: 'Одиниця виміру' }"
        :button="{
                    icon: false,
                    buttonHidden: true
                  }"
        :modal="modal"
        @updateItemModal="closeModal"/>
    <v-autocomplete
        v-model="model"
        color="grey lighten-1"
        :items="entries"
        :loading="isLoading"
        :search-input.sync="search"
        dense
        :filled="filled"
        :chips="chips"
        :small-chips="chips"
        :multiple="multiple"
        :disabled="disabled"
        no-data-text="Не знайдено жодної одиниці виміру"
        hide-details
        label="Одиниця виміру"
        placeholder="почніть вводити текст для пошуку"
        @change="$emit('autocompleteChange', model)"
        :solo="solo"
        :style="style_"
        :menu-props="menuProps"
        clearable
        return-object
        :class="class_"
    >
      <template v-slot:selection="data" v-if="chips">
        <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
            class="my-1"
            color="white"
        >
          <v-icon color="secondary" class="mr-3">
            mdi-bank-outline
          </v-icon>
          <span class="grey--text text--darken-3">{{ data.item.text }}</span>
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-icon>
            <v-icon color="success darken-1" size="20" class="mr-3">
              mdi-account-supervisor-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle v-html="data.item.text"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:prepend-item>
        <div class="mb-5 prepend-list-item">
          <div class="prepend-list-item-buttons">
            <div class="a-list">
              <a href="" @click.prevent="openList">Показати усі елементи у списку</a>
            </div>
            <div class="a-button">
              <v-btn @click="openModal" small class="grey darken-2" depressed>
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import SelectAPI from '@/utils/axios/autocomplite'
import {ALERT_SHOW} from "@/store/actions/alert";
import {getCityIcon} from "@/utils/icons";

export default {
  name: "AC_UnitType",
  props: {
    filled: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    style_: {
      type: String,
      default: ''
    },
    class_: {
      type: String,
      default: ''
    }
  },
  components: {
    MainModal: () => import('@/components/modal/MainModal'),
    UnitTypeView: () => import('@/components/UnitTypeView')
  },
  computed: {
    menuProps() {
      return {
        closeOnContentClick: this.modal
      }
    }
  },
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    modal: false,
    navigation: false,
    selectedItem: null
  }),
  methods: {
    chooseByRow() {
      this.fetch(true)
    },
    selectedRow(payload) {
      if (payload === undefined) {
        this.selectedItem = null
      } else {
        this.selectedItem = Object.assign({}, payload)
      }
    },
    openList() {
      this.navigation = true
    },
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
      this.fetch()
    },
    fetch(model) {
      SelectAPI.unit_type_select()
        .then(res => {
          const { data } = res
          this.entries = data
          const obj = this.entries.find(item => item.value === this.value)
          this.multiple ? this.model.push(obj) : this.model = obj

          if (model) {
            this.navigation = false
            this.model = this.entries.find(item => item.value === this.selectedItem.id)
            this.$emit('autocompleteChange', this.model)
          }
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
        .finally(() => (this.isLoading = false))
    },
    remove (item) {
      const fElement = this.model.find(i => i.value === item.value)
      const index = this.model.indexOf(fElement)
      if (index >= 0) this.model.splice(index, 1)
    },
    getCityIcon
  },
  watch: {
    search () {
      if (this.entries.length > 0) return

      if (this.isLoading) return

      this.isLoading = true

      this.fetch()
    },
    value: {
      handler(payload) {
        if (payload) {
          this.model = this.entries.find(item => item.value === payload)
        } else {
          this.model = this.multiple ? [] : null
        }
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">
  .main-height:deep(.v-input__control) {
    min-height: 34px !important;
  }
  .main-height:deep(.v-input__control .v-input__slot) {
    min-height: 34px !important;
  }
  .close {
    position: absolute;
    right: -8px;
    top: -9px;
    z-index: 1;
  }
</style>